import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import FormSimpleAjax from '../components/FormSimpleAjax'
import Image from '../components/Image'
import Layout from '../components/Layout'
import './ContactPage.css'

// Export Template for use in CMS preview
export const ContactPageTemplate = ({ title, sectionHero, sectionInfo }) => (
  <div className="contact-page">
    <PageHeader title={title} backgroundImage={sectionHero.featuredImage} />

    <section className="yellow">
      <div className="wide half flex">
        <div className="item">
          <h2>We’d love to hear from you!</h2>
        </div>

        {/* details section */}

        <div className="item third flex">
          {!!sectionInfo.phone && (
            <div className="item">
              <h5>Phone</h5>

              <a href={`tel:${sectionInfo.phone}`} rel="nofollow">
                <p>{sectionInfo.phone}</p>
              </a>
            </div>
          )}

          {!!sectionInfo.email && (
            <div className="item">
              <h5>Email</h5>

              <a href={`mailto:${sectionInfo.email}`} rel="nofollow">
                <p>{sectionInfo.email}</p>
              </a>
            </div>
          )}

          {!!sectionInfo.hours && (
            <div className="item">
              <h5>Opening Hours</h5>

              <p>{sectionInfo.hours}</p>
            </div>
          )}

          {!!sectionInfo.address && (
            <div className="item">
              <h5>Office</h5>
              <p>{sectionInfo.address}</p>
            </div>
          )}

          {!!sectionInfo.pobox && (
            <div className="item">
              <h5>PO Box</h5>

              <p>{sectionInfo.pobox}</p>
            </div>
          )}

          {!!sectionInfo.parking && (
            <div className="item">
              <h5>Parking</h5>

              <p>{sectionInfo.parking}</p>
            </div>
          )}
        </div>
      </div>

      {/* form section */}

      <div className="wide half flex">
        <div className="item">
          <div className="map">
            <Image src="/images/map.jpg" alt="How to find us" />
          </div>
          <p>QBCC Licence No: 15115046</p>
          <p>VIC Licence No: DP-AD 43731</p>
          <p>NSW Accreditation No: 6323</p>
        </div>
        <div className="item">
          <FormSimpleAjax />
        </div>
      </div>
    </section>
  </div>
)

const ContactPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ContactPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        sectionHero {
          featuredImage
        }
        sectionInfo {
          address
          pobox
          email
          phone
          hours
          parking
        }
      }
    }
  }
`
